import React, { useEffect, useRef, useState } from "react"
import styled from "styled-components"

import { Button, Flex, Icon, Input } from "../components"
import { postContactInquiry } from "../services/developersService"
import { NavLink, useHistory } from "react-router-dom"
import { useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { ProcessedProfile } from "../processData"
import { useSnackbar } from "notistack"

export const Contact = () => {
  const { handleSubmit, register, errors, reset } = useForm()
  const [submittedData, setSubmittedData] = React.useState({})
  const [showMessage, setShowMessage] = useState(false)
  const [message, setMessage] = useState("")
  const [favorites, setFavorites] = useState([] as ProcessedProfile[])
  const didMountRef = useRef(false)
  const [t, i18n] = useTranslation("common")
  const { enqueueSnackbar } = useSnackbar()

  useEffect(() => {
    if (didMountRef.current) {
      send()
    } else {
      didMountRef.current = true
    }
    updateFavorite()
    window.addEventListener("storage", updateFavorite)
    return () => {
      window.removeEventListener("storage", updateFavorite)
    }
  }, [submittedData])

  const onSubmit = (data: React.SetStateAction<{}>) => {
    setSubmittedData(data)
  }

  const reportError = (errorText: string) => {
    setMessage(errorText)
    setShowMessage(true)
  }

  const send = async () => {
    const developers = favorites.map((profile) => profile.id)
    const data: any = submittedData
    data["developers"] = developers
    const response = await postContactInquiry(data)

    if (response.status === 200) {
      window.history.replaceState({}, "", "/contact?contactForm=ok")
      setMessage(response.data.message)
      setShowMessage(true)
      import("react-facebook-pixel")
        .then((module) => module.default)
        .then((ReactPixel) => {
          ReactPixel.track("Contact", data)
        })
      reset()
    } else if (response.status === 400) {
      const error = (response.data.errors && response.data.errors[0]) || {
        errorType: null,
      }
      if (error.errorType === "INVALID_EMAIL") {
        reportError(t("form.emailInvalidResponse"))
      } else {
        reportError(t("form.errorSendingResponse"))
      }
    } else {
      reportError(t("form.retryResponse"))
    }
  }

  const removeFavorite = (id: Number) => {
    const filteredFavorites = favorites.filter((profile) => profile.id !== id)
    setFavorites(filteredFavorites)
    localStorage.setItem("favorites", JSON.stringify(filteredFavorites))
    window.dispatchEvent(new Event("storage"))
    enqueueSnackbar(t("common.favoritesRemoved"), {
      variant: "info",
    })
  }

  const updateFavorite = () => {
    setFavorites(
      JSON.parse(
        localStorage.getItem("favorites") || "[]"
      ) as ProcessedProfile[]
    )
  }

  const history = useHistory()

  const getTitleUrl = (profile: ProcessedProfile) => {
    const presentation = profile.presentations[i18n.language]
      ? profile.presentations[i18n.language]
      : Object.values(profile.presentations)[0]
    const titleUrl = encodeURIComponent(presentation.title.replace(/\s+/g, "-"))
    return titleUrl
  }

  return (
    <Flex row justify="center">
      <Flex column paddingBottom="35.52px" style={{ maxWidth: 800 }}>
        {favorites.length > 0 ? (
          <>
            <Title>{t("contact.askMore")}</Title>
            <Subtitle>{t("contact.selected")}</Subtitle>
            <div>
              {favorites.map((profile) => (
                <RoleText key={profile.id}>
                  <IdLabel>ID {`${profile.id}`.padStart(5, "0")}</IdLabel>
                  <StyledNavLink
                    to={`/profile/${getTitleUrl(profile)}/${profile.id}`}
                  >
                    {profile.presentations[i18n.language]
                      ? profile.presentations[i18n.language].title
                      : Object.values(profile.presentations)[0].title}
                  </StyledNavLink>
                  <RemoveFavoriteButton
                    onClick={() => {
                      removeFavorite(profile.id)
                    }}
                  >
                    <Icon
                      style={{ marginBottom: "2px" }}
                      size={12}
                      icon="mobiilinavi_sulje_dark"
                    />
                  </RemoveFavoriteButton>
                </RoleText>
              ))}
            </div>
          </>
        ) : (
          <>
            <Title>{t("common.contact")}</Title>
            <Subtitle>{t("contact.subTitle")}</Subtitle>
          </>
        )}
        <InfoText>
          {t("contact.infoText")}
          <a href="tel:+358406613857">+358 40 661 3857</a>
          {t("contact.infoTextEmail")}
          <a href="mailto:anssi.heijasto@sysart.fi">anssi.heijasto@sysart.fi</a>
          {"."}
        </InfoText>
        <form onSubmit={handleSubmit(onSubmit)} style={{ maxWidth: "550px" }}>
          <Flex column spacing="30px">
            <FormField label={t("form.firstNameLabel")}>
              <FormErrorText>{errors.firstName?.message}</FormErrorText>
              <Input
                type="text"
                placeholder={t("form.firstNamePlaceHolder")}
                name="firstName"
                id="firstName"
                ref={register({
                  required: {
                    message: t("form.firstNameRequired"),
                    value: true,
                  },
                })}
              />
            </FormField>
            <FormField label={t("form.lastNameLabel")}>
              <FormErrorText>{errors.lastName?.message}</FormErrorText>
              <Input
                type="text"
                placeholder={t("form.lastNamePlaceHolder")}
                name="lastName"
                id="lastName"
                ref={register({
                  required: {
                    message: t("form.lastNameRequired"),
                    value: true,
                  },
                })}
              />
            </FormField>
            <FormField label={t("form.emailLabel")}>
              <FormErrorText>{errors.email?.message}</FormErrorText>
              <Input
                type="text"
                placeholder={t("form.emailPlaceHolder")}
                name="email"
                id="email"
                ref={register({
                  required: {
                    message: t("form.emailRequired"),
                    value: true,
                  },
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: t("form.emailInvalid"),
                  },
                })}
              />
            </FormField>
            <FormField label={t("form.phoneNumberLabel")}>
              <FormErrorText>{errors.phoneNumber?.message}</FormErrorText>
              <Input
                type="text"
                placeholder={t("form.phoneNumberPlaceHolder")}
                name="phoneNumber"
                id="phoneNumber"
                ref={register({
                  required: {
                    message: t("form.phoneNumberRequired"),
                    value: true,
                  },
                  pattern: {
                    value: /^(\+\d{1,3}[- ]?)?\d{10}$/i,
                    message: t("form.phoneNumberInvalid"),
                  },
                })}
              />
            </FormField>
            <FormField label={t("form.messageLabel")}>
              <FormErrorText>{errors.message?.message}</FormErrorText>
              <Input
                type="text"
                placeholder={t("form.messagePlaceHolder")}
                name="message"
                id="message"
                ref={register({
                  required: {
                    message: t("form.messageRequired"),
                    value: true,
                  },
                })}
              />
            </FormField>
            <Button variant2 style={{ width: 200 }} type="submit">
              <div>{t("form.sendLabel")}</div>
              <Icon icon="nuoli_linkki_oikea" size={20} />
            </Button>
            {showMessage && (
              <FormLabelText
                id="contactFormMessage"
                dangerouslySetInnerHTML={{ __html: message }}
              />
            )}
          </Flex>
        </form>
        <StyledButton onClick={history?.goBack}>
          <Flex row align="center" spacing="10px" paddingTop="17px">
            <Icon icon="nuoli_linkki_vasen_sininen" size={18} />
            <ButtonText>{t("common.goBack")}</ButtonText>
          </Flex>
        </StyledButton>
      </Flex>
    </Flex>
  )
}

const Title = styled.h1`
  color: ${({ theme }) => theme.color.primaryDark};
  font-size: ${({ theme }) => theme.fontSize.heading};
  font-weight: ${({ theme }) => theme.weight.semiBold};
  line-height: 44px;
  max-width: 550px;
`

const RoleText = styled.h2`
  color: ${({ theme }) => theme.color.primaryDark};
  font-size: ${({ theme }) => theme.fontSize.large};
  font-weight: ${({ theme }) => theme.weight.bold};
  line-height: 22px;
`

const IdLabel = styled.div`
  line-height: 13px;
  margin-top: 10px;
  font-size: ${({ theme }) => theme.fontSize.tiny};
  color: ${({ theme }) => theme.color.black};
  font-weight: ${({ theme }) => theme.weight.semiBold};

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    line-height: 11px;
    font-size: ${({ theme }) => theme.fontSize.extraTiny};
    font-weight: ${({ theme }) => theme.weight.medium};
  }
`

const Subtitle = styled.h2`
  color: ${({ theme }) => theme.color.primaryDark};
  font-size: ${({ theme }) => theme.fontSize.medium};
  font-weight: ${({ theme }) => theme.weight.semiBold};
  line-height: 22px;
  margin-top: 20px;
`

const InfoText = styled.div`
  margin-top: 15px;
  margin-bottom: 64px;

  font-weight: ${({ theme }) => theme.weight.regular};
  font-size: ${({ theme }) => theme.fontSize.small};
  line-height: 17px;
`
const StyledNavLink = styled(NavLink)`
  text-decoration: none;
  &:hover {
    color: ${({ theme }) => theme.color.primary};
  }
  width: 100%;
`

const RemoveFavoriteButton = styled.button`
  margin-left: 10px;
  font-size: 17px;
  font-weight: ${({ theme }) => theme.weight.semiBold};
  color: ${({ theme }) => theme.color.primaryDark};
  border-radius: 20px;
  border: 2px solid ${({ theme }) => theme.color.primaryDark};
  width: 20px;
  height: 20px;
  padding: 1px 1px;
  &:hover {
    cursor: pointer;
  }
`

interface FormFieldProps {
  label: string
}

const FormField: React.FC<FormFieldProps> = (props) => (
  <FormLabel>
    <FormLabelText>{props.label}</FormLabelText>
    {props.children}
  </FormLabel>
)

const FormLabelText = styled.div`
  margin-bottom: 10px;
`

const FormErrorText = styled.div`
  margin-bottom: 10px;
  color: red;
`

const FormLabel = styled.label`
  color: ${({ theme }) => theme.color.primaryDark};
  font-size: ${({ theme }) => theme.fontSize.tiny};
  font-weight: ${({ theme }) => theme.weight.semiBold};
  line-height: 13.41px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
`

const StyledButton = styled.div`
  text-decoration: none;
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
`

const ButtonText = styled.div`
  color: ${({ theme }) => theme.color.primary};
  font-weight: ${({ theme }) => theme.weight.extraBold};
  font-size: ${({ theme }) => theme.fontSize.tiny};
  line-height: 13px;

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    font-size: ${({ theme }) => theme.fontSize.extraTiny};
    line-height: 11px;
  }
`
